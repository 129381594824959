import { template as template_6d69b8f9726d4857bc325e65d9014059 } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import bodyClass from "discourse/helpers/body-class";
const AddCategoryTagClasses = template_6d69b8f9726d4857bc325e65d9014059(`
  {{#if @category}}
    {{bodyClass "category" (concat "category-" @category.fullSlug)}}
  {{/if}}

  {{#each @tags as |tag|}}
    {{bodyClass (concat "tag-" tag)}}
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AddCategoryTagClasses;
