import { template as template_111bf2b8e609466a818e3d5f5dfbd923 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_111bf2b8e609466a818e3d5f5dfbd923(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
